import { getJSON, setJSON } from '../util/storage';

/**
 * Updates rating stars for product tiles based on supplied rating and count
 * @param {Object} $ratingContainer - DOM element for rating container
 * @param {Number} rating - rating out of 5
 * @param {Number} reviewCount - number of reviews
 */
const updateRatingStars = ($ratingContainer, rating, reviewCount) => {
    const isComparePage = document.querySelector('main')?.dataset.action === 'Compare-Show';

    const $emptyStars = $ratingContainer.querySelector('.js-rating-stars-empty');
    const $filledStars = $ratingContainer.querySelector('.js-rating-stars-filled');
    const $countContainer = $ratingContainer.querySelector('.js-review-count-container');
    const $count = $ratingContainer.querySelector('.js-review-count');
    const $ratingOutOfFive = $ratingContainer.querySelector('.js-rating-out-of-five');

    const ratingPercent = (Math.round(rating * 10) / 10 / 5) * 100;
    const ratingText = `${(Math.round(rating * 10) / 10).toString().replace(/\.0$/, '')} / 5`;

    if ($emptyStars) {
        if (isComparePage) {
            $emptyStars.classList.remove('product-attr__rating__stars--loading');
            $emptyStars.classList.add('product-attr__rating__stars--empty');
        } else {
            $emptyStars.classList.remove('product-tile__rating__stars--loading');
            $emptyStars.classList.add('product-tile__rating__stars--empty');
        }
    }

    if ($filledStars) {
        $filledStars.style.width = `${ratingPercent}%`; // eslint-disable-line no-param-reassign
    }

    if ($countContainer) {
        $countContainer.classList.remove('d-none');
    }

    if ($count) {
        $count.innerText = reviewCount; // eslint-disable-line no-param-reassign
    }

    if ($ratingOutOfFive) {
        $ratingOutOfFive.innerText = ratingText; // eslint-disable-line no-param-reassign
    }
};

/**
 * Extracts rating and counts from Yotpo rating container, and updates our custom rating display with these values
 * @param {Object} $yotpoRatingContainer - DOM element for yotpo rating container
 */
const updateTile = ($yotpoRatingContainer) => {
    let count;
    let rating;

    const $label = $yotpoRatingContainer.querySelector('a');
    if ($label) {
        count = parseInt($label.innerHTML.replace(/\D/g, ''), 10);
    }

    const $srText = $yotpoRatingContainer.querySelector('.yotpo-stars .sr-only');
    if ($srText) {
        const extractedRating = $srText.innerHTML.match(/([\d.]+)/);
        if (extractedRating.length > 0) {
            rating = parseFloat(extractedRating[0], 10);
        }
    }

    const $ratingContainer = $yotpoRatingContainer
        .closest('.product-tile, td')
        .querySelector('.js-review-stars');

    if ($ratingContainer) {
        if (count && rating) {
            const productID = $yotpoRatingContainer.dataset.productId;

            setJSON(`productRating-${productID}`, {
                rating,
                count,
            });

            updateRatingStars($ratingContainer, rating, count);
            $yotpoRatingContainer.remove(); // Remove Yotpo containers for improved performance on load more
        } else {
            $ratingContainer.remove(); // If count and rating not found, remove skeleton
        }
    }
};

/**
 * Initializes listeners on Yotpo rating containers in order to update our display of ratings when Yotpo loads them
 */
const loadRatingsFromYotpo = () => {
    const $yotpoTileRatings = document.querySelectorAll('.js-yotpo-ratings-container');

    $yotpoTileRatings.forEach(($yotpoRatingContainer) => {
        if ($yotpoRatingContainer.querySelector('.yotpo-stars')) {
            updateTile($yotpoRatingContainer);
        } else {
            const yotpoTileObserver = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (
                        mutation.type === 'childList' &&
                        $yotpoRatingContainer.querySelector('.yotpo-stars')
                    ) {
                        updateTile($yotpoRatingContainer);
                        yotpoTileObserver.disconnect();
                    }
                });
            });

            yotpoTileObserver.observe($yotpoRatingContainer, {
                attributes: false,
                childList: true,
                characterData: false,
            });
        }
    });
};

/**
 * Loads ratings from local storage if they exist, otherwise initializes loading them from Yotpo
 */
const init = () => {
    const $yotpoTileRatings = document.querySelectorAll('.js-yotpo-ratings-container');

    $yotpoTileRatings.forEach(($yotpoRatingContainer) => {
        const productID = $yotpoRatingContainer.dataset.productId;
        const existingRating = getJSON(`productRating-${productID}`);

        if (existingRating) {
            const $ratingContainer = $yotpoRatingContainer
                .closest('.product-tile, td')
                .querySelector('.js-review-stars');

            if ($ratingContainer) {
                updateRatingStars($ratingContainer, existingRating.rating, existingRating.count);
                $yotpoRatingContainer.remove();
            }
        } else {
            // Add classes to support loading ratings from Yotpo
            $yotpoRatingContainer.classList.add('yotpo', 'bottomLine');
        }
    });

    /* global Yotpo, yotpo */
    if (typeof Yotpo !== 'undefined' && typeof yotpo !== 'undefined') {
        const api = new Yotpo.API(yotpo);
        api.refreshWidgets();
    }

    loadRatingsFromYotpo();
};

/**
 * Handles displaying reviews on tiles loaded after the initial page load
 */
const loadRatingsForNewTiles = () => {
    init();
};

export default init;
export { init, loadRatingsForNewTiles };
