import DISImage from './dis-image';

import * as productDIS from '../../../../../../../plugin_dis_patagonia/cartridge/preferences/image_config_DIS.js';

class ProductDISImage extends DISImage {
    constructor() {
        super();
        this.config = { ...productDIS };
    }

    connectedCallback() {
        if (super.connectedCallback) {
            super.connectedCallback();
        }

        this.config = this.imageOptimizationHelpers.appendProductSizesToDIS(this.config);
    }
}

export default ProductDISImage;
