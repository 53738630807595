import { html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import DISImage from './dis-image';
import { toBoolean } from '../../modules/BaseElement';

import { collection as imageSettings } from '../../../../../../../plugin_dis_patagonia/cartridge/preferences/library_config_DIS';

const lqip = (scope) =>
    `src="${scope.getLQIP()}"
    data-lowsrc="${scope.getLQIP()}"
    `;

const getSourceAttributes = (scope) =>
    scope.base.includes('demandware.static')
        ? `data-src=${scope.getSrc()}
            data-srcset="${scope.getSrcSet()}"
            ${scope.showLQIP ? lqip(scope) : ''}
            data-sizes="auto"`
        : `src=${scope.getSrc()}
            loading="lazy"`;

const template = (scope) => html`
    ${unsafeHTML(`<img
        class="${scope.classes?.includes('lazyload') ? '' : 'lazyload'} ${scope.classes}"
        ${getSourceAttributes(scope)}
        ${scope.styles ? `style="${scope.styles}"` : ''}
        ${scope.alt ? `alt="${scope.alt}"` : ''}
        ${scope.width ? `width="${scope.width}"` : ''}
        ${scope.height ? `height="${scope.height}"` : ''}
        decoding="async"
    />`)}
`;

class LibraryDISImage extends DISImage {
    static get properties() {
        return {
            alt: {
                type: String,
            },
            base: {
                type: String,
            },
            classes: {
                type: String,
            },
            styles: {
                type: String,
            },
            width: { type: String },
            height: { type: String },
            showLQIP: {
                attribute: 'show-lqip',
                type: Boolean,
                converter: {
                    fromAttribute: (val) => toBoolean(val),
                },
            },
        };
    }

    constructor() {
        super();
        this.config = {};

        imageSettings.forEach((item) => {
            Object.keys(item).forEach((size) => {
                this.config[size] = item[size];
            });
        });
    }

    render() {
        return template(this);
    }
}

export default LibraryDISImage;
