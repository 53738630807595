import debounce from 'lodash/debounce';
import camelCase from 'lodash/camelCase';
import { setItem, getItem, getJSON } from '../util/storage';
import { loadAfterComplete } from '../util/domHelpers';

let tealium = null;
const loadedModals = {};

const isCheckout = !!document.querySelector('#checkout-main');

const checkForModalHeader = (modal) => {
    if (!modal) {
        return;
    }

    // Do we already have a header?
    if (modal.querySelector('modal-sticky-header')) {
        return;
    }
    if ($(modal).find('[data-modal-sticky-header]').length) {
        const stickyHeader = document.createElement('modal-sticky-header');
        $(modal).find('.modal-dialog').prepend(stickyHeader);
    }
};

const setModalInURL = (modal) => {
    if (!window.history) {
        return;
    }
    const newURL = new URL(window.location);
    newURL.hash = modal.id;
    window.history.replaceState({ modal: modal.id }, '', newURL);
};

const resetModalInURL = () => {
    setItem('activeModalScroll', 0);
    if (!window.history) {
        return;
    }
    const newURL = new URL(window.location);
    newURL.hash = '';
    window.history.replaceState({}, '', newURL);
};

const setUpModalURLEvents = (modal) => {
    $(modal).on('shown.bs.modal', () => setModalInURL(modal));
    $(modal).on('hidden.bs.modal', resetModalInURL);

    $(modal).on('hidden.bs.modal', () => {
        $(modal).find('.modal-body')[0].scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    });

    $(modal).one('shown.bs.modal', () => {
        if (tealium) {
            // automatic tealium send for any modal open
            tealium.collect(`openModal_${camelCase(modal.id)}`);
        }
    });

    // save scroll position
    $(modal)
        .find('.modal-body')
        .on('scroll', (e) => {
            setItem('activeModalScroll', `${modal.id}|${e.target.scrollTop}`);
        });
};

const checkForOpenModals = () => {
    if (window.foundOpenModal) {
        return;
    }
    if (window.location.hash && window.location.hash.length > 2) {
        const activeModal = document.querySelector(`.modal__full${window.location.hash}`);
        if (!activeModal) {
            return;
        }
        window.foundOpenModal = true;
        checkForModalHeader(activeModal);

        $(activeModal).one('shown.bs.modal', () => {
            setUpModalURLEvents(activeModal);
            setTimeout(() => {
                if (
                    'scrollBy' in document.body &&
                    getItem('activeModalScroll') &&
                    getItem('activeModalScroll').split('|').shift() === activeModal.id
                ) {
                    $(activeModal)
                        .find('.modal-body')[0]
                        .scrollBy({
                            top: parseInt(getItem('activeModalScroll').split('|').pop(), 10),
                            left: 0,
                            behavior: 'smooth',
                        });
                }
            }, 750);
        });

        $(activeModal).modal('show');
    }
};

/**
 * @name getProductPromoStatus
 * @description Loads a remote modal and displays it, ensuring if it has already been shown, it won't be re-downloaded
 * @param {string} url url of the modal to display
 * @param {string} successEvent optional event to fire after the modal has been shown
 * @param {boolean} forceReload optional, forces modal to reload if it has already been shown
 * @param {boolean} doNotShow optional, does not show modal so it can be shown manually
 */
const showRemoteModal = (url, successEvent, forceReload, doNotShow) => {
    if (!url) {
        return;
    }

    // If not already loaded, fetch modal
    if (!(url in loadedModals && !forceReload)) {
        fetch(url, { method: 'GET' })
            .then((response) => response.text())
            .then((html) => {
                const parser = new DOMParser();
                const $html = parser.parseFromString(html, 'text/html');
                const $modal = $html.querySelector('.modal');

                if (!$modal) {
                    return;
                }

                // If force reload is true, and the existing modal is on the page, remove it before re-inserting the newly fetched modal
                if ($modal.id && forceReload) {
                    const existingModal = document.getElementById($modal.id);
                    if (existingModal) {
                        existingModal.remove();
                    }
                }

                // Add an ID if not present
                if (!$modal.id) {
                    $modal.id =
                        Date.now().toString(36) +
                        Math.random().toString(36).substring(2, 12).padStart(12, 0);
                }

                // Store that modal has been loaded on the page
                loadedModals[url] = $modal.id;

                let $pageWrapper = document.querySelector(isCheckout ? '.page' : '.page-wrapper');
                if (!$pageWrapper) {
                    $pageWrapper = document.querySelector('body');
                }

                // Add modal to page. Needs to use jQuery/dom7 so inline styling and scripts are appended properly. We can refactor this to use a helper based on jQuery append source code if we get rid of jQuery and dom7.
                $html.querySelectorAll('script').forEach(($script) => {
                    const newScript = document.createElement('script');
                    newScript.text = $script.text;

                    document.head.appendChild(newScript);
                });

                [$html.head, $html.body].forEach(($node) => {
                    if ($node.hasChildNodes()) {
                        $node.childNodes.forEach(($element) => {
                            $($pageWrapper).append($element);
                        });
                    }
                });

                const $modalForms = $modal.querySelectorAll('form');
                if ($modalForms.length > 0) {
                    // Add custom input handling if forms are present in modal
                    document.dispatchEvent(new Event('initCustomInputs'));

                    // Initialize validation
                    $modalForms.forEach(($form) => {
                        const pristine = new Pristine($form);
                        pristine.validate();
                    });
                }

                if (!doNotShow) {
                    const modal = new window.bootstrap.Modal($modal);
                    modal.show();
                }

                if (successEvent) {
                    document.dispatchEvent(new Event(successEvent));
                } else if ($modal.dataset.event) {
                    document.dispatchEvent(new Event($modal.dataset.event));
                }
            })
            .catch((error) => {
                console.error('Error loading modal: ', error);
            });
    } else {
        // If already loaded, show modal
        const $modal = document.getElementById(loadedModals[url]);

        if ($modal) {
            if (!doNotShow) {
                const modal = window.bootstrap.Modal.getOrCreateInstance($modal);
                modal.show();
            }

            if (successEvent) {
                document.dispatchEvent(new Event(successEvent));
            } else if ($modal.dataset.event) {
                document.dispatchEvent(new Event($modal.dataset.event));
            }
        }
    }
};

// fire once
const globalModalEvents = () => {
    // handle modal close from within modal content
    $(document).on('click', '[data-modal-close]', function (e) {
        e.preventDefault();
        $(this).parents('.modal')?.modal('hide');
    });

    $(document).on('click', '.modal-backdrop.fade.show', () => {
        $('.modal.show').modal('hide');
    });

    $(document).on('click', '[data-bs-toggle=modal]', (e) => {
        const modal = document.querySelector(e.currentTarget.dataset.bsTarget);
        checkForModalHeader(modal);

        if (
            modal &&
            modal.classList.contains('modal__full') &&
            !modal.classList.contains('modal__content') &&
            !('excludeFromHistory' in modal.dataset)
        ) {
            // full modals count as pages
            setUpModalURLEvents(modal);
        }
    });

    $(document).ready(() => {
        import('../app/tealium/tealium.js').then(({ default: tealiumLib }) => {
            tealium = tealiumLib;
        });

        checkForOpenModals();

        document.addEventListener(
            'LazyAjaxLoaded',
            debounce(() => checkForOpenModals(), 500)
        );
    });

    document.addEventListener('showRemoteModal', (e) => {
        showRemoteModal(
            e.detail.url,
            e.detail.successEvent,
            e.detail.forceReload,
            e.detail.doNotShow
        );
    });

    // Load and Show Remote Modals
    document.addEventListener('click', (event) => {
        const button = event.target.closest('[data-remote-modal-url]');
        if (!button) return;

        if (button.dataset?.modalSuccessEvent) {
            if (button.dataset?.modalForceReload) {
                showRemoteModal(
                    button.dataset?.remoteModalUrl,
                    button.dataset.modalSuccessEvent,
                    true
                );
            } else {
                showRemoteModal(button.dataset?.remoteModalUrl, button.dataset.modalSuccessEvent);
            }
        } else if (button.dataset?.modalForceReload) {
            showRemoteModal(button.dataset?.remoteModalUrl, null, true);
        } else {
            showRemoteModal(button.dataset?.remoteModalUrl);
        }
    });

    document.addEventListener('showRemoteModalFromElement', (e) => {
        showRemoteModal(e.detail.element?.dataset?.remoteModalUrl);
    });
};

// fire on init and ajax
const initModals = () => {
    const main = $('main');
    const modalWrapper = main.length ? main : $('body');

    // move modal HTML out to more top-level
    // element to allow for viewport takeover
    $('[modal-mover]')
        .not('body > [modal-mover], main > [modal-mover]')
        .each((index, modal) => {
            modalWrapper.append(modal);
        });
};

function fireNotificationModal(options) {
    let notificationModalURL = $('.modal-notification').data('endpoint');
    if (options && options.contentAsset) {
        notificationModalURL += `&cid=${options.contentAsset}`;
        $.ajax({ url: notificationModalURL, dataType: 'html' }).done((html) => {
            document.getElementById('modalNotificationContent').innerHTML = html;
            $('#modalNotification').modal('show');
            document.cookie = 'firedNotificationModal=true; path=/';
        });
    }
}

function showModal($modalElement) {
    $modalElement.modal('show');
}

export default function () {
    globalModalEvents();
    initModals();

    document.addEventListener('LazyAjaxLoaded', initModals);

    const modalsToLoad = getJSON('modals');
    let modalShown = false;

    if (modalsToLoad && modalsToLoad.length) {
        modalsToLoad.forEach((item) => {
            // Skip showing modals in checkout if they do not have the showOnCheckout flag set
            if (isCheckout && !item.showOnCheckout) {
                return;
            }

            fetch(item.url, { method: 'GET' })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`Unable to load modal from ${item.url}`);
                    }

                    return response.text();
                })
                .then((response) => {
                    const $modalContainer = $(`<div>${response}</div>`);
                    const $pageWrapper = isCheckout ? $('.page') : $('.page-wrapper');
                    $pageWrapper.append($modalContainer);
                    const $modalForms = $modalContainer.find('form');

                    if ($modalForms.length) {
                        // Add custom input handling if forms are present in modal
                        document.dispatchEvent(new Event('initCustomInputs'));

                        // Initialize validation
                        $modalForms.each(function () {
                            const pristine = new Pristine($(this)[0]);
                            pristine.validate();
                        });
                    }

                    if (item.customEvent) {
                        document.dispatchEvent(new Event(item.customEvent));
                    }

                    if (item.showOnLoad) {
                        loadAfterComplete(showModal, $modalContainer.find('.modal'));
                    }
                });

            if (item.showOnLoad) {
                modalShown = true;
            }
        });

        if (modalShown) {
            window.localStorage.removeItem('modals');
        }
    }

    if (modalShown || isCheckout) {
        return;
    }

    const globalModalDiv = document.getElementById('globalModalData');
    const cookieID = globalModalDiv ? globalModalDiv.dataset.cookieId : null;
    const firingSequenceArray =
        globalModalDiv && 'firingSequenceArray' in globalModalDiv.dataset
            ? JSON.parse(globalModalDiv.dataset.firingSequenceArray)
            : [];
    const existingCookie =
        cookieID &&
        decodeURIComponent(document.cookie)
            .split('; ')
            .filter((item) => item.includes(cookieID));
    const existingCookieValue =
        cookieID && existingCookie.length > 0
            ? parseInt(existingCookie[0].substring(cookieID.length + 1), 10)
            : null;

    const modalAlreadyShown = existingCookieValue === 0; // existingCookieValue will be 0 if the modal was shown
    const pageViews = existingCookieValue ? existingCookieValue + 1 : 1;

    const $notificationModal = $('.modal-notification .modal-body');

    if (cookieID && !modalAlreadyShown) {
        document.cookie = `${cookieID}=${pageViews}; path=/`;
    }

    // Show modal if not already dismissed and page view count is included in the firing sequence array
    if (!modalAlreadyShown && firingSequenceArray.includes(pageViews)) {
        $('#modalNotification').on('hide.bs.modal', () => {
            document.cookie = `${cookieID}=0; path=/`; // Set cookie value to 0 if the modal is dismissed
        });

        $('#modalNotification').modal('show');
    }

    // manually trigger modal
    $(document).on('notificationModal', (event, options) => {
        // Only show the modal if one hasn't been fired and if there isn't a slot already displayed
        if (
            typeof options !== 'undefined' &&
            options !== null &&
            (existingCookie ? existingCookie.length === 0 : true) &&
            $notificationModal.length > 0 &&
            $notificationModal.children().length === 0
        ) {
            fireNotificationModal(options);
        }
    });
}
