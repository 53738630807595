import { BaseElement } from '../../modules/BaseElement';

import * as Optimize from '../../../../../../../plugin_dis_patagonia/cartridge/scripts/helpers/Optimize.js';

const paramMap = { scaleWidth: 'sw', scaleHeight: 'sh', quality: 'q' };

class DISImage extends BaseElement {
    constructor() {
        super();
        this.imageOptimizationHelpers = Optimize;
        this.config = {};
    }

    buildURL(params = {}) {
        const builtURL = new URL(this.baseURL);
        Object.keys(params).forEach((key) => {
            builtURL.searchParams.set(paramMap[key] || key, params[key]);
        });
        return builtURL.toString();
    }

    getSrcSet(sizes) {
        let lastSizeParsed = 0;
        this.imageSizes = Object.keys(this.config)
            .filter((key) => key !== 'missingImages' && key !== 'viewTypeMapping')
            .sort((a, b) => {
                try {
                    const aNum = Number(a);
                    const bNum = Number(b);
                    if (Number.isNaN(aNum)) {
                        return 0;
                    }
                    if (Number.isNaN(bNum)) {
                        return 0;
                    }
                    return aNum > bNum ? 1 : -1;
                } catch (e) {
                    console.warn(e);
                }
                return 0;
            })
            .filter((size) => {
                try {
                    const num = Number(size);
                    if (Number.isNaN(num)) {
                        return true;
                    }
                    if (num > lastSizeParsed + 250) {
                        lastSizeParsed = num;
                        return true;
                    }
                } catch (e) {
                    console.warn(e);
                    return false;
                }
                return false;
            });

        const parts = [];
        const widths = [];

        this.imageSizes.forEach((size) => {
            if (this.config[size] && (!sizes || sizes.includes(size))) {
                const configWidth = this.config[size].scaleWidth;
                const width =
                    configWidth % 4 === 0 ? configWidth : Math.floor(configWidth / 4.0) * 4;

                if (!widths.includes(width)) {
                    parts.push(
                        `${this.buildURL(this.config[size]).replace(`sw=${configWidth}`, `sw=${width}`)} ${width}w`
                    );
                    widths.push(width);
                }
            }
        });

        return parts.join(', ');
    }

    getPlaceholder() {
        return this.basePlaceholderURL.toString();
    }

    getSrc() {
        return this.baseURL.toString();
    }

    get basePlaceholderURL() {
        return new URL(this.placeholder);
    }

    get baseURL() {
        return new URL(this.base);
    }

    getLQIP() {
        return this.buildURL(this.config.lqip);
    }
}

export default DISImage;
